import { useRoute } from '#app'
import { inject, type InjectionKey, provide } from 'vue'

export type EmbedParams = {
    mode: 'same-tab' | 'new-tab' | 'lightbox'
}

const embedParamsKey: InjectionKey<EmbedParams> = Symbol('EMBED_PARAMS')
export function useEmbedParams() {
    return inject(embedParamsKey, null)
}

export function provideEmbedParams() {
    const route = useRoute()
    const embedParams: EmbedParams = {
        mode: 'same-tab'
    }
    if (route.query.embedMode === 'same-tab') {
        embedParams.mode = 'same-tab'
    } else if (route.query.embedMode === 'new-tab') {
        embedParams.mode = 'new-tab'
    } else if (route.query.embedMode === 'lightbox') {
        embedParams.mode = 'lightbox'
    }

    provide(embedParamsKey, embedParams)
}
